



















































































import { Component, Vue } from "vue-property-decorator";
import { bizScene } from "@/mocks/dict";
import goods from "@/mocks/goods";

@Component
export default class Space extends Vue {
  private adv = require("@/assets/images/adv/01.png");
  private scene = bizScene;
  private goods = goods;
}
