export default [
  {
    img: require("@/assets/images/demo/001.jpg"),
    title: "小型缸体展"
  },
  {
    img: require("@/assets/images/demo/002.jpg"),
    title: "萌宠展览"
  },
  {
    img: require("@/assets/images/demo/003.jpg"),
    title: "骨骼展示"
  },
  {
    img: require("@/assets/images/demo/004.jpg"),
    title: "植物标本展览"
  },
  {
    img: require("@/assets/images/demo/005.jpg"),
    title: "小型缸体展"
  },
  {
    img: require("@/assets/images/demo/006.jpg"),
    title: "生境展览"
  },
  {
    img: require("@/assets/images/demo/007.jpg"),
    title: "模型展示"
  },
  {
    img: require("@/assets/images/demo/008.jpg"),
    title: "化石展览"
  },
  {
    img: require("@/assets/images/demo/009.jpg"),
    title: "黑暗空间"
  },
  {
    img: require("@/assets/images/demo/010.jpg"),
    title: "科普教育"
  },
  {
    img: require("@/assets/images/demo/011.jpg"),
    title: "国宝自然博物馆"
  },
  {
    img: require("@/assets/images/demo/012.jpg"),
    title: "自然摄影展览"
  }
];
