// 关于我们
export const about = {
  content: [
    "北京树行途生态教育科技有限公司，总部位于北京市海淀区，致力于为企业及教育育机构提供自然内容解决方案。树行途自然教育秉承打造“自然陪伴”的理念，用科学家的视角发现和理解自然界中的点滴。尤其陪伴正在成长中的孩子们，将科学的自然教育理念融入到未成年人的课程体系中，为现阶段中小学教育以及幼儿教育，提供当前教育改革所急需的自然社科的实践版块内容，同时通过空间打造、互动装置以及产品研发等多方面工具，为学生创造一个可互动的沉浸式教学场景。学生通过观察，感受，发现，探索，思考及互动获得知识，掌握科学思考方式及自主解决问题的能力，成为热爱且拥有探究能力的学习者。",
    "树行途生态教育，拥有一支专业打造自然教育课程内容的研发团队，自然展示空间传达的策展团队，以及实体及课程落地的运营团队。团队目前以科普教育基地、生态展览、课程及产品设计等，落地了北京、上海、石家庄、济南、重庆、宁波、南通等多个城市，并构建了一套完备的运营方案。",
    "树行途生态教育，建立了自己的知识专家库，影像及IP数据库，产品原型及代理库，同时与国内外多家知名设计机构、衍生品生产机构、教育机构、政府机构及各级自然保护区建立了良好的合作关系。"
  ],
  images: [
    require("@/assets/images/about-01.jpg"),
    require("@/assets/images/about-02.jpg"),
    require("@/assets/images/about-03.jpg")
  ]
};

// 我们荣誉
export const honor = [
  require("@/assets/images/honor/01.jpg"),
  require("@/assets/images/honor/02.jpg"),
  require("@/assets/images/honor/03.jpg"),
  require("@/assets/images/honor/04.jpg"),
  require("@/assets/images/honor/05.jpg"),
  require("@/assets/images/honor/06.jpg"),
  require("@/assets/images/honor/07.jpg")
];

// 业务场景
export const bizScene = [
  {
    label: "专业场景",
    color: "#64c880",
    desc:
      "专业自然生态调查，设计，改造及实施北京动物园大型“溪流”大鲵生态展示空间",
    img: require("@/assets/images/adv/adv01.jpg")
  },
  {
    label: "消费场景",
    color: "#ffcd76",
    desc:
      "上海MoStudy遇见自然主题展览3场6000人商业地产空间，生态小镇、酒店及民宿以及主题 乐园，提供自然生态领域方案设计及运营服务",
    img: require("@/assets/images/adv/adv02.jpg")
  },
  {
    label: "教育场景",
    color: "#7cb0da",
    desc:
      "教育场景重庆青年汇巅峰乐园:科普基地内容占地超1000平米+18门自研课程重庆市教委授牌市级科普教育基地配合自主研发的自然教育类课程，打造自然特色科普教育基地、社会实践大课堂等费景",
    img: require("@/assets/images/adv/adv03.jpg")
  }
];

export const course = [
  {
    tagName: "你好·国宝",
    title: "第一玉龙",
    content: "龙的传人",
    about: [
      "了解龙的起源",
      "知道中华图腾的意义",
      "了解6500年前的工艺",
      "探索中华五千年文明之前的生活",
      "手工：麒麟涂色"
    ]
  },
  {
    tagName: "你好·自然",
    title: "有用的植物",
    content: "植物的生存方式和贡献",
    about: [
      "植物的定义",
      "了解光合作用三要素",
      "理解植物是生态系统基础",
      "手工：光合作用游戏+涂色表现"
    ]
  },
  {
    tagName: "你好·国宝",
    title: "“众人协田”牛骨刻辞",
    content: "中国文字发展起源",
    about: [
      "了解中国文字的起源",
      "探索文字的意思",
      "了解古代文字的特点及内容",
      "中华文字的传承方式",
      "古人的智慧",
      "甲骨文盒子制作"
    ]
  },
  {
    tagName: "你好·自然",
    title: "花儿为谁开",
    content: "植物进化历史及开花意义",
    about: [
      "了解花的结构和作用",
      "了解花的传粉策略",
      "手工：剪纸拼贴画——对称花园"
    ]
  }
];

export const support = [
  {
    tagName: "教案",
    enName: "LESSON PLAN",
    className: "red",
    content: [
      "以《小学科学课程标准》，《初中生物科学标准》，《初中科学课程标准》《中国学生发展核心素养》，《美国K-12科学素养基准》为理论指导超详细课程讲义，细化到每分钟教学话术，课后习成任务单回顾，帮助学生巩因知识"
    ]
  },
  {
    tagName: "课件",
    enName: "COURSE WARE",
    className: "orange",
    content: [
      "在线PPT，森括引入、铺垫、衔接、进解、拓屉、总结全环节 内容精英、生动有趣的裘述风格适宜教学使用"
    ]
  },
  {
    tagName: "备课视频",
    enName: "LESSON PREPARATION VIDEO",
    className: "yellow",
    content: [
      "高效讲牌理程知识造点及微学目标",
      "还原最初课程设计概念",
      "使教师培训轻松高效"
    ]
  },
  {
    tagName: "课堂视频",
    enName: "LIVE VIDEO",
    className: "green",
    content: ["真是课堂教学场景生动有趣的师生交互辅助无老师的每一堂课"]
  },
  {
    tagName: "知识点延展",
    enName: "KNOWLEDGE EXTENSION",
    className: "blue",
    content: [
      "小学科学课，初中生物，物理及化学教学的跨学科延展素材",
      "课堂外的延展知识点整理",
      "线下提问的理论依据"
    ]
  },
  {
    tagName: "教学素材及视频",
    enName: "TEACHING MATERIAL",
    className: "gray",
    content: [
      "用于校本课延展素材，包含动植物相关影像资料",
      "让校本课更生动",
      "提高自然内容的准确性"
    ]
  }
];

export const cultivate = [
  {
    name: "招生方案",
    enName: "KNOWLEDGE EXTENSION",
    content: [
      "小学科学课，初中生物、物理及化学教学的跨学科延展素材",
      "课堂外的延展知识点整理",
      "线下提问的理论依据"
    ]
  },
  {
    name: "宣传材料",
    enName: "PROMOTIONAL MATERIALS",
    content: [
      "用于校本课延展素材，包含动植物相关影像资料让校本课更生动",
      "提高自然内容的准确性"
    ]
  }
];
